import React from 'react';
import TextComponent from "../TextComponent";
import StagesCard from "../StagesCard/StagesCard";
import ButtonSpec from "../ButtonSpec";

const StagesSro = () => {
    const cardsData = [
        {
            frontText: 'Подача заявки, обсуждение условий с персональным менеджером, после чего заключим договор на комплексное оказание услуг под ключ',
            stagesText:'/1',
        },
        {
            frontText: 'Подбор СРО по вашим препочтениям и самым выгодным для вас условиям, подготовка документов для отправки в СРО',
            stagesText:'/2',

        },
        {
            frontText: 'Совершение всех необходимых финансовых расходов(взносов) и оформление бумаг для для завершения оформления документов в полном объёме',
            stagesText:'/3',

        },
        {
            frontText: 'Получения выписки о вступлении в члены ассоциации из НОПРИЗ в день оплаты счетов! Дальнейшая поддержка от нашего агенства и помощь с любым СРО!',
            stagesText:'/4',

        },
    ];
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div id='stages'>
            <TextComponent className='mx-6 xl:mx-16 xl:mt-24 lg:mt-12 lg:my-14 ' text='Этапы получение СРО'/>
            <div className='bg-[#FDFDFD61] p-6 xl:p-12 shadow-2xl'>
                <div className=" cursor-default grid lg:grid-cols-4 gap-8 justify-center flex-col lg:flex-row">
                    {cardsData.map((card, index) => (
                        <StagesCard key={index} stagesText={card.stagesText}  imageUrl={card.imageUrl} textFront={card.frontText}/>
                    ))}
                </div>
                <div onClick={() => scrollToSection('questions')}>


                <ButtonSpec className=' bg-gradient-hover whitespace-pre leading-8 lg:w-max mx-auto px-4 justify-center flex !mt-10' text='Хочу бесплатную консультацию'/>
                </div>
            </div>

        </div>
    );
};

export default StagesSro;