import React from 'react';

const TextComponent = ({ text, className = '' }) => {
    const words = text.split(' ');
    const firstWord = words[0];
    const restOfText = words.slice(1).join(' ');

    return (
        <p className={`  font-medium ${className}`}>
            <span className='font-bold'>{firstWord}</span> {restOfText}

        </p>
    );
};

export default TextComponent;