import React from 'react';

const ButtonSpec = ({ text, className = '' ,disabled}) => {
    return (
        <button disabled={disabled} type='submit' className={`mt-4 w-full cursor-pointer  text-white text-center py-2  bg-#11141D rounded-lg text-[16px] lg:text-[24px] font-medium ${className}`}>
            {text}
        </button>
    );
};

export default ButtonSpec;