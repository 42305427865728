import React from 'react';
import CallFooter from '../../assets/icons/callFooter.svg'
import Email from '../../assets/icons/email.svg'
import Logo from '../../assets/icons/logoBlack.svg'
const Footer = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className=" opacity-[27] lg:mt-32 px-6 xl:px-16 py-8 grid lg:grid-cols-3 font-medium text-[20px] lg:text-[24px] items-start">

            <div className='cursor-default'>
                <img onClick={() => scrollToSection('main')} className='cursor-pointer h-20' alt='logo' src={Logo}/>
                <div>
                    <div>
                        Санкт-Петербург, Академический переулок, дом 8, литер Б, помещ. 3-А
                    </div>
                    <div className='mb-6 lg:mb-0'>
                        ПН-ПТ 9:00 - 18:00
                    </div>
                </div>
            </div>
            <div className=" flex flex-col gap-2 lg:mx-auto mb-6 lg:mb-0 cursor-default">
                <div className='cursor-pointer hover:text-[#4A62FF]' onClick={() => scrollToSection('about')}>Необходимость СРО</div>
                <div className='cursor-pointer hover:text-[#4A62FF]' onClick={() => scrollToSection('species-sro')}>Виды СРО</div>
                <div className='cursor-pointer hover:text-[#4A62FF]' onClick={() => scrollToSection('stages')}>Этапы получения СРО</div>
            </div>
            <div className="  lg:mx-auto">
                <div className='mb-2 cursor-default'>Наши контакты</div>
                <div className='flex items-center gap-1.5'>
                    <img alt='call' src={CallFooter}/>
                    <a href="tel:+79219952534" className='cursor-pointer hover:text-blue-600 hover:underline'>
                        +7 (921) 995 25 34
                    </a>
                </div>

                <div className='flex items-center gap-1.5 mt-3'>
                    <img alt='email' src={Email}/>
                    <a href="mailto:magic.consult@mail.ru" className='cursor-default ml-0.5 hover:text-blue-600 hover:underline'>magic.consult@mail.ru</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;