import React, { useState } from 'react';
import SpeciesText from "../SpeciesText";
import ButtonSpec from "../ButtonSpec";
const Card = ({ textFront, textBack, imageUrl,price }) => {
    const [flipped, setFlipped] = useState(false);

    const handleFlip = () => {
        setFlipped(!flipped);
    };

    return (
        <div className={`card w-full h-[350px]  ${flipped ? 'flipped' : ''}`}>
            <div className="front justify-end px-16 w-[440px] shadow py-6 rounded-2xl h-[350px] grad-bg-species flex flex-col">
                <div className='justify-center flex flex-col items-center'>
                    <img src={imageUrl} alt={textFront} />
                    <SpeciesText className='cursor-default whitespace-pre  text-[25px] lg:text-[16px] xl:text-[22px]' text={textFront} />
                </div>
                <SpeciesText className='mt-auto mx-auto cursor-default whitespace-pre  text-[25px] lg:text-[16px] xl:text-[22px]' text={price} />
                <div className='cursor-pointer' onClick={handleFlip}>
                    <ButtonSpec className=' bg-gradient-hover' text='Узнать больше' />
                </div>
            </div>

            <div className="back w-[440px] shadow pt-2.5 pb-6 rounded-2xl h-[400px] grad-bg-species flex flex-col items-start">
                <div className='flex items-center'>
                    <SpeciesText className='px-4 lg:px-0 lg:ml-6 text-[20px] lg:text-[16px] xl:text-[22px] cursor-default' text={textBack} />
                </div>
                <div className='w-full mx-auto px-6 mt-auto cursor-pointer' onClick={handleFlip}>
                    <ButtonSpec className=' bg-gradient-hover' text='Назад' />
                </div>
            </div>
        </div>
    );
};
export default Card
