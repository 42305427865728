import React from 'react';
import Header from "../Header/Header";
import '../../index.css';

const InfoCard = ({ title, description }) => (
    <div className='rounded-[12px] shadow px-4 py-2 bg-white w-[260px] lg:w-[325px] lg:flex-shrink flex-shrink-0 grad-bg'>
        <div className='text-[20px] lg:text-[32px]'>{title}</div>
        <div className='font-[300] mt-6 text-[16px] lg:text-[20px]'>{description}</div>
    </div>
);

const Main = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div id='main'>
            <Header />
            <div className="main-bg lg:px-8 px-0 xl:px-16 pt-10 lg:pt-5 pb-10">
                <h1 className='text-[36px] cursor-default lg:text-[64px] xl:text-[96px] text-white font-bold mt-8 lg:mt-28 leading-none lg:px-0 px-4'>
                    Выгодное и <span className='text-[#67DC1E]'>безопасное</span> вступление в СРО
                </h1>
                <div className='rounded-[24px] cursor-default xl:w-9/12 bg-[#4A62FF26] gap-8 lg:overflow-x-auto overflow-x-auto justify-between bg-opacity-15 px-6 lg:px-5 py-10 flex mt-6 lg:mt-20'>
                    <InfoCard
                        title="День в день"
                        description="Оформление допуска в день обращения"
                    />
                    <InfoCard
                        title="Под ключ"
                        description="Подготовка документов под ключ"
                    />
                    <InfoCard
                        title="Доставка на дом"
                        description="Доставка оригиналов в офис и на дом"
                    />
                </div>
                <div
                    onClick={() => scrollToSection('stages')}
                    className='bg-[#72C042] cursor-pointer mx-6 lg:mx-0 text-white text-[16px] text-center lg:text-[20px] font-medium lg:w-fit py-4 lg:py-3 px-24 mt-4 lg:mt-8 rounded-[8px]'
                >
                    Узнать больше
                </div>
            </div>
        </div>
    );
};

export default Main;