import React from 'react';
import SpeciesText from "../SpeciesText";

const StagesCard = ({ textFront,stagesText }) => {

    return (
                <div>
                    <div className='flex flex-col h-full bg-[#FDFDFD] shadow-2xl px-6 pb-8 lg:py-8'>
                        <SpeciesText className='text-[96px] font-bold' text={stagesText}/>
                        <SpeciesText className='cursor-default text-[20px] lg:text-[12px] xl:text-[22px]' text={textFront}/>
                    </div>

                </div>


    );
};

export default StagesCard;