import React, {useEffect, useState} from 'react';
import Call from '../../assets/icons/call.svg';
import Logo from '../../assets/icons/logo.svg';
import Burger from '../../assets/icons/burger.svg';
import Close from '../../assets/icons/closeBurger.svg';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        if (window.innerWidth <= 768) { // Можно изменить 768 на нужное значение
            setIsOpen(!isOpen);
        }
    };
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);


    return (
        <div  className='flex w-full justify-between items-center text-[24px] fixed z-50 text-white bg-black bg-opacity-80 px-6 lg:px-16  lg:py-2.5'>
            <img onClick={() => scrollToSection('main')} src={Logo} className='cursor-pointer h-20' alt='logo'/>
            <div className='hidden lg:flex gap-11 cursor-default'>
                <div onClick={() => scrollToSection('about')} className="cursor-pointer">О нас</div>
                <div onClick={() => scrollToSection('species-sro')} className="cursor-pointer">Виды СРО</div>
                <div onClick={() => scrollToSection('contacts')} className="cursor-pointer">Контакты</div>
            </div>
            <div onClick={() => scrollToSection('questions')} className='cursor-pointer lg:flex hidden items-center lg:mb-0 mb-auto px-4 py-1.5 bg-[#4A62FF] gap-4 rounded-full'>
                <img src={Call} alt='call' />
                <div>Связаться с нами</div>
            </div>

            {/* Бургер-меню */}
            <div className='lg:hidden'>
                <button onClick={toggleMenu}>
                    {isOpen ? <div>
                        <img alt='close' className='w-10  mt-2' src={Close}/>
                    </div> : <div>
                        <img alt='burger' className='w-10 mt-2' src={Burger}/>
                    </div>}
                </button>
            </div>

            {/* Мобильное меню */}
            {isOpen && (
                <div className='absolute transition-all duration-700 lg:hidden ease-in-out h-screen top-14 left-0 w-full bg-black bg-opacity-95 text-white flex flex-col px-6'>
                    <div onClick={() => scrollToSection('about')} className='py-2 cursor-pointer'>О нас</div>
                    <div onClick={() => scrollToSection('species-sro')} className='py-2 cursor-pointer'>Виды СРО</div>
                    <div onClick={() => scrollToSection('contacts')} className='py-2 cursor-pointer'>Контакты</div>
                    <div className='py-2 cursor-pointer'>Связаться с нами</div>
                </div>
            )}
        </div>
    );
};

export default Header;