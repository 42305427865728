import React from 'react';
import TextComponent from "../TextComponent";
import ButtonSpec from "../ButtonSpec";
import Card from "../Card/Card";
import build from '../../assets/icons/build.svg'
import proec from '../../assets/icons/proec.svg'
import worker from '../../assets/icons/worker.svg'
const SpeciesSro = () => {


    const cardsData = [
        {
            frontText: 'СРО строителей',
            backText: 'Строительство: Членство в СРО строителей обязательно для строительных компаний и позволяет им участвовать в подрядах, обеспечивая соблюдение единых стандартов.',
            imageUrl: worker,
            price:'От  100 000 руб.'
        },
        {
            frontText: 'СРО проектировщиков',
            backText: 'Проектирование: Членство в СРО проектировщиков необходимо для проектных организаций, что дает возможность участвовать в контрактах и гарантирует соблюдение стандартов в проектировании.',
            imageUrl: proec,
            price:'От  50 000 руб.'

        },
        {
            frontText: 'СРО изыскателей',
            backText: 'Изыскание: Членство в СРО изыскателей обязательно для компаний, занимающихся инженерными изысканиями, и позволяет им участвовать в контрактах, поддерживая единые правила работы.',
            imageUrl: build,
            price:'От  50 000 руб.'

        },
    ];
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div id='species-sro' className="lg:my-14">
            <TextComponent className='lg:mb-14 mb-6' text='Виды СРО'/>
                <div className="flex justify-center items-center gap-8 flex-col lg:flex-row">
                    {cardsData.map((card, index) => (
                        <Card key={index} price={card.price} imageUrl={card.imageUrl} textFront={card.frontText} textBack={card.backText}/>
                    ))}
                </div>
            <div onClick={() => scrollToSection('price')}>
                <ButtonSpec className='lg:w-max leading-8 whitespace-pre bg-gradient-hover mx-auto px-20 justify-center flex !mt-10' text='Рассчитать стоимость'/>
            </div>
        </div>
    );
};


export default SpeciesSro;