import React from 'react';

const Policy = ({ firstText,secText, classNameFirst = '' }) => {
    return (

    <div className={` mt-8 text-[14px] cursor-default mx-auto ${classNameFirst}`}>{firstText} <span className='text-[#11141D]'>{secText}</span>
    </div>
    );
};

export default Policy;