import React from 'react';
import TextComponent from "../TextComponent";
import Plus from "../../assets/icons/plus.svg";
const About = () => {
    return (
        <div id='about' className=' lg:pb-20 cursor-default'>
            <TextComponent text="Что это и кому это нужно?" />
            <div className='lg:mt-16 mt-4 flex-col xl:flex-row flex xl:items-center xl:gap-16'>
                <div className='rounded-[16px] shadow xl:w-1/2 px-4 py-6 xl:p-14 text-[16px] lg:text-[20px] leading-6 font-medium'>
                    <div>
                        Выписка нужна компаниям, участвующим в строительстве капитальных зданий и занимающихся
                        строительством,<br/> проектированием или инженерными изысканиями.
                    </div>
                    <div className='mt-6'>
                        Вступление даёт фирме возможность участвовать в тендерах,<br/> получать больше крупных заказов,
                        подтвердить соответствие<br/> качества работ установленным требованиям и многое другое.
                    </div>
                    <div className='lg:mt-20 mt-4'>
                        В 82% случаев перед предприятием встает необходимость срочно<br/> вступить, иначе крупный заказ
                        достанется другой организации.
                    </div>
                    <div className='mt-6'>
                        Поэтому мы поможем привести ваши документы для вступления<br/>в СРО  в порядок и оформим вступление от 1 дня.
                    </div>
                </div>
                <div className='grid md:grid-cols-2 xl:mx-auto gap-10 xl:gap-20 mt-12 xl:mt-0'>
                    <div className='flex lg:items-start items-center md:flex-col gap-4 lg:gap-0'>
                      <img src={Plus} alt='plus'/>
                        <div className='text-[20px] lg:text-[32px] font-medium mt-3 leading-8'>Работаем по всей<br/> стране</div>
                    </div>
                    <div className='flex lg:items-start items-center md:flex-col gap-4 lg:gap-0'>
                        <img src={Plus} alt='plus'/>
                        <div className='text-[20px] lg:text-[32px] font-medium mt-3 leading-8'>Вступление без НРС<br/> специалистов
                        </div>
                    </div>
                    <div className='flex lg:items-start items-center md:flex-col gap-4 lg:gap-0'>
                        <img src={Plus} alt='plus'/>
                        <div className='text-[20px] lg:text-[32px] font-medium mt-3 leading-8'>Индивидуальный<br/> подход к каждому
                        </div>
                    </div>
                    <div className='flex lg:items-start items-center md:flex-col gap-4 lg:gap-0'>
                        <img src={Plus} alt='plus'/>
                        <div className='text-[20px] lg:text-[32px] font-medium mt-3 leading-8'>Допуск 50 000 руб.</div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default About;