import React, { useState} from 'react';
import ButtonSpec from "../ButtonSpec";
import PhoneInput from "react-phone-input-2";

const Form = ({inputClass='',textClass='',text='',mainClass=''}) => {
    const [firstName, setFirstName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (phoneNumber.length < 10) {
            setError('Номер телефона должен содержать не менее 10 символов.');
            setIsSubmitting(false);
            return;
        }

        const emailRegex = /^[^s@]+@[^s@]+.[^s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Неверный формат email.');
            setIsSubmitting(false);
            return;
        }

        const message = `Имя: ${firstName}, Номер: +${phoneNumber}, Почта: ${email}`;
        const token = '7845635240:AAGfCLY7pTIonaiPDdrSwg_mosnA7mSS6CA';
        const chatIds = ['1272103463', '897654643'];
        const url = `https://api.telegram.org/bot${token}/sendMessage`;

        try {
            for (const chatId of chatIds) {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        chat_id: chatId,
                        text: message,
                    }),
                });

                const data = await response.json();
                if (!data.ok) {
                    alert(`Ошибка при отправке заявки в чат с ID: ${chatId}`);
                }
            }

            if (window.ym) {
                window.ym(98838966, 'reachGoal', 'zakaz');
            }

            alert('Заявка успешно отправлена!');
            setFirstName('');
            setPhoneNumber('');
            setEmail('');
            setError('');
        } catch (error) {
            console.error('Ошибка:', error);
            alert('Ошибка при отправке заявки.');
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleFirstNameChange = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Zа-яА-ЯёЁ]*$/.test(value) && value.length <= 11) {
            const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
            setFirstName(formattedValue);
            setError('');
        } else {
            setError('Имя может содержать только буквы и не более 11 символов.');
        }
    };


    const handlePhoneChange = (value) => {
        const numericValue = value.replace(/D/g, '');

        setPhoneNumber(numericValue);


        if (/[a-zA-Z]/.test(value)) {
            setError('Нельзя вводить буквы');
        } else if (numericValue.length > 10) {
            setError('Номер может содержать не более 10 символов.');
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;

        if (value.length <= 40) {
            setEmail(value);

            const emailRegex = /^[^s@]+@[^s@]+.[^s@]+$/;
            if (emailRegex.test(value)) {
                setError('');
            } else {
                setError('Неверный формат email');
            }
        } else {
            setError('Должно быть не более 40 символов');
        }
    };




    return (
        <div className={`mt-6 ${mainClass}`}>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="first_name" className={`${textClass} block mb-2 text-lg font-medium`}>Ваше
                        имя</label>
                    <input
                        type="text"
                        id="first_name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        className={`${inputClass} rounded-xl pl-4 w-full placeholder-black outline-none focus:outline-none`}
                        placeholder="Ваше имя"
                        required
                    />
                </div>
                <div className='my-4'>
                    <label htmlFor="first_name" className={`${textClass} block mb-2 text-lg font-medium`}>Ваша
                        почта</label>
                    <input
                        type="text"
                        id="email"
                        value={email}
                        onChange={handleChange}
                        className={`${inputClass} rounded-xl pl-4 w-full placeholder-black outline-none focus:outline-none`}
                        placeholder="example@domain.ru"
                        required
                    />
                </div>

                <div>
                    <label htmlFor="phone_number" className={`${textClass} block mb-2 text-lg font-medium`}>Номер
                        телефона</label>
                    <PhoneInput
                        specialLabel=''
                        country={'ru'}
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        className={`${inputClass}  rounded-xl pl-4 w-full outline-none focus:outline-none`}
                        inputStyle={{
                            background: 'transparent',
                            outline: 'none',
                            width: '100%'

                        }}
                        placeholder="+7 800 965 65 88"
                        required
                    />
                </div>

                {error && <p className="text-red-500 mt-2">{error}</p>}
                <ButtonSpec disabled={isSubmitting} text={text} type="submit"
                            className={` ${isSubmitting ? 'bg-gray-400' : 'bg-gradient-hover'} mx-auto whitespace-pre text-[16px] lg:w-max px-12 justify-center flex !mt-8`}>
                </ButtonSpec>
            </form>

        </div>
    );
};

export default Form;