import Main from "./components/Main/Main";
import WeWork from "./components/WeWork/WeWork";
import About from "./components/About/About";
import SpeciesSro from "./components/SpeciesSro/SpeciesSro";
import StagesSro from "./components/StagesSro/StagesSro";
import PriceSro from "./components/PriceSro/PriceSro";
import Questions from "./components/Questions/Questions";
import Contacts from "./components/Contacts/Contacts";
import Footer from "./components/Footer/Footer";

function App() {
  return (
      <div>
          <Main/>
          <div className="xl:px-16 px-6">
          <WeWork/>
          <About/>
          <SpeciesSro/>
          </div>
          <StagesSro/>
          <Questions/>
          <div className='xl:px-16 px-6'>
              <Contacts/>
          </div>
          <PriceSro/>
          <Footer/>
      </div>
  );
}

export default App;
