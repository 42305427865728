import React from 'react';
import TextComponent from "../TextComponent";
import Police from "../Police";
import Form from "../Form/Form";

const PriceSro = () => {
    return (
        <div id='price' className='xl:px-16'>
            <TextComponent className='xl:mx-16 mx-6  lg:mx-0 leading-none xl:mt-24 lg:mt-12 mb-6 lg:my-14 lg:text-center' text='Узнайте стоимость вашего допуска СРО'/>
            <div className='flex w- flex-col px-6 lg:px-0 lg:items-center rounded-2xl lg:rounded-3xl w-full mb-6 lg:mb-12 py-8 bg-[#4A62FF]'>
                <Form text='Рассчитать стоимость' btnClass='mx-auto' textClass='text-white' inputClass='py-3 bg-white w-full' mainClass='lg:w-2/5'/>
                <Police classNameFirst='text-[#FDFDFD]' firstText='Нажимая на кнопку, вы даёте согласие на' secText='обработку ваших персональных данных'/>
            </div>
        </div>
    );
};

export default PriceSro;