import React from 'react';
import Country from '../../assets/images/country.webp'
import TextComponent from "../TextComponent";
const WeWork = () => {
    return (
        <div className='lg:mt-10 xl:mt-24 lg:pb-10'>
            <TextComponent className='leading-none' text="Мы работаем по всей стране" />
            <img alt='country' className='mx-auto' src={Country}/>
        </div>
    );
};

export default WeWork;