import React from 'react';
import Que from '../../assets/images/que.webp'
import Police from "../Police";
import Form from "../Form/Form";
const Questions = () => {
    return (
        <div id='questions' className="flex items-center lg:px-40 lg:mb-12 flex-col lg:flex-row lg:pt-36 pt-12 lg:mt-0">

            <div className='flex flex-col p-6 lg:p-12 xl:p-16 lg:h-[700px] rounded-3xl w-full  xl:w-2/3  bg-[#FDFDFD] shadow-2xl relative'>
                <div className='text-[40px] text-[#11141D] font-semibold leading-10 cursor-default'>У вас остались вопросы?</div>
                <div className='text-[16px] text-[#11141D] mt-1 cursor-default'>
                    Заполните и отправьте форму. <br/>Мы свяжемся с вами в ближайшее время!
                </div>
             <Form text='Получить консультацию' inputClass='py-4 bg-[#D8F5C6]'/>
                <Police classNameFirst='text-[#BCBCBC]' firstText='Нажимая на кнопку, вы даёте согласие на' secText='обработку ваших персональных данных'/>
            </div>
            <img alt='que' className='lg:h-[700px] lg:w-1/2 w-full lg:ml-[-2rem] mt-[-2rem] lg:mt-0' src={Que}/>
        </div>
    );
};

export default Questions;