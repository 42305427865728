import React from 'react';
import TextComponent from "../TextComponent";

const Contacts = () => {
    return (
        <div id='contacts' className="lg:mb-12 ">
            <div className='flex  items-center justify-between mb-6 lg:mb-0'>
                <TextComponent
                    text='Контакты'/>
                <a href="tel:+79219952534"
                   className='text-[#11141D] text-[32px] cursor-pointer hidden lg:block pt-20 hover:text-blue-600 hover:underline'>
                    +7 (921) 995 25 34
                </a>

            </div>
            <iframe
                className='w-full '
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31979.647367274785!2d30.2418544855874!3d59.93665613757517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469631b06f7c8bdd%3A0x683868c8fdebf9c!2z0JvQuNGC0LXRgCDQkQ!5e0!3m2!1sru!2sru!4v1729597328345!5m2!1sru!2sru"
                width="600" height="450" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            <div className='grid lg:grid-cols-2 text-[#11141D] text-[20px] lg:text-[32px] cursor-default'>
                <div>
                    <div className=' lg:hidden block'>
                        <div>
                            <div className='lg:mt-14 mt-6 lg:mb-6 font-semibold'>Наш телефон</div>
                        </div>
                        <a href="tel:+79219952534" className='cursor-pointer hover:text-blue-600 hover:underline text-[#11141D] '>+7 (921) 995 25 34
                        </a>
                    </div>
                    <div className='lg:mt-14 mb-6 font-semibold'>Офис в Санкт-Петербурге</div>
                    <div>
                        Санкт-Петербург, Академический переулок, дом 8, литер Б, помещ. 3-А
                    </div>
                    <div>
                        ПН-ПТ 9:00 - 18:00
                    </div>
                </div>
                <div className='lg:mx-auto'>
                    <div className='lg:mt-14 mt-4 lg:mb-6 font-semibold'>Наша почта</div>
                    <a href="mailto:magic.consult@mail.ru" className='hover:text-blue-600 hover:underline'>
                        magic.consult@mail.ru
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contacts;